<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.findByOrg")
        }}</v-toolbar-title>
        <v-spacer />
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('user.searchByOrgOrUserDetails')"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-data-table
          dense
          :headers="headers"
          :items="getOrganizations"
          item-key="id"
          :search="search"
          :custom-filter="deepFilter"
          single-select
          @click:row="navigateToSelected"
        >
          <template #item.name="{ item }">
            <v-btn text tile>
              <v-icon small class="mr-2">mdi-domain</v-icon>
              {{ item.name }}</v-btn
            >
          </template>
          <template #item.status="{ item }">
            <v-chip
              v-if="notEmptyArray(item.users)"
              label
              small
              class="ma-1 px-1"
            >
              {{ item.users.length }} Users
            </v-chip>
            <v-chip
              v-if="item.users && item.users.some((s) => !s.active)"
              label
              small
              color="red"
              text-color="white"
              class="ma-1 px-1"
            >
              Inactive Users
            </v-chip>
            <v-chip
              v-if="!notEmptyArray(item.users)"
              label
              small
              color="blue"
              text-color="white"
              class="ma-1 px-1"
            >
              No Users
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "OrgSelector",
  props: {
    route: String,
  },
  data() {
    return {
      search: "",
      deleteDialog: false,
      headers: [
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("org.acronym"), value: "acronym" },
        { text: this.$t("general.status"), value: "status" },
      ],
    };
  },
  computed: {
    ...mapGetters("organizations", ["getOrganizations"]),
  },
  created() {
    this.loadOrganizations();
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations"]),

    navigateToSelected({ id }) {
      const route = { name: this.route, params: { org: id } };
      this.$router.push(route);
    },
    deepFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        (value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1 ||
          (item &&
            this.notEmptyArray(item.users) &&
            item.users.some((s) => {
              let ds = _.toString(_.toArray(s));
              ds = ds.replace("false", "inactive");
              return this.deepFilter(ds, search);
            })))
      );
    },
  },
};
</script>

<style>
.v-data-table tr {
  cursor: pointer !important;
}
</style>
